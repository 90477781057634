'use client';

import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import Image from 'next/image';
import Link from 'next/link';
import './searchBar.scss';
import { useEffect, useRef, useState } from 'react';
import { fetchReportsWithKeyword } from '@/app/utils/fetchdata';
import coherentmi_icon from '@/app/favicon.ico';

export default function SearchBar({ show, setShow }) {
  const [searchResult, setSearchResult] = useState([]);
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef(null);

  function handleSearch(e) {
    setKeyword(e.target.value);
  }

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.select();
    inputRef.current.value = '';
    setSearchResult([]);
  }, [show]);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (keyword !== '') {
        const result = await fetchReportsWithKeyword(keyword);
        setSearchResult(result);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [keyword]);

  // //For the Search Bar
  const EmptyComponent = () => {
    return (
      <>
        <div className="mx-auto text-center my-3">
          <h5 className="font-bold">
            Ensure that you have entered correct keyword!
          </h5>
          <p className={'my-2'}>
            Unable to find the report,{' '}
            <Link href={'/contact-us'} className="text-blue-500 font-semibold">
              contact us
            </Link>{' '}
            for your specific requirements.
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={show ? 'searchModal' : 'hidden'}
        onClick={() => {
          setShow(false);
          setSearchResult([]);
        }}
      >
        <div className="container">
          <div className="searchWrapper">
            <div className="searchInput " onClick={(e) => e.stopPropagation()}>
              <div>
                <button
                  className="btn btn-close-search my-2 my-sm-0 search-open"
                  title="Search"
                  onClick={setShow}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"></path>
                  </svg>
                </button>
              </div>
              <span className="left-pan">
                <HiOutlineMagnifyingGlass size={20} />
              </span>
              <input
                id="keyword"
                type="search"
                onChange={handleSearch}
                ref={inputRef}
                autoComplete="off"
                placeholder="Search reports..."
                className="p-2 w-full mx-2"
              />
            </div>
            <ul className="searchResultList">
              {searchResult.length !== 0 ? (
                searchResult.map((item, index) => {
                  return (
                    <li
                      key={item.CustomName + index}
                      className="flex flex-row gap-3 items-center text-center"
                    >
                      {' '}
                      <Image
                        src={coherentmi_icon}
                        height={30}
                        width={30}
                        alt="coherentmi_icon"
                      />
                      <Link
                        href={`/industry-reports/${item.CustomName}`}
                        className="w-full text-start"
                      >
                        {item.keyword}
                      </Link>
                    </li>
                  );
                })
              ) : keyword && searchResult.length === 0 ? (
                <EmptyComponent />
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </div>{' '}
    </>
  );
}
